@use "sass:math";

@import "~@valencediscovery/kernel.styles/dist/variables.scss";
@import "~@valencediscovery/kernel.styles/dist/breakpoints.scss";

.navigation-menu {
  display: flex;
  flex-direction: column;
  background-color: $navigation-color;
  height: 100vh;
  overflow-y: scroll;
  max-width: $navigationWidth;

  transition: visibility 0s, opacity 1s, linear;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .ant-menu-item {
    min-height: 40px;
  }
}

.subnavigation-menu {
  display: flex;
  flex-direction: column;
  height: 100vh;

  min-width: $navigationWidth;
  max-width: $navigationWidth;
  .ant-menu-item {
    min-height: 40px;
  }
}

.navigation-org-avatar-icon-expanded > .ant-menu-submenu-title {
  padding-left: 12px !important;
}

.navigation-user-avatar-icon-expanded > .ant-menu-submenu-title {
  padding-left: 16px !important;
}

.navigation-avatar-icon-collapsed > .ant-menu-submenu-title {
  padding: 0 calc(50% - 14px) !important;
}

.navigation-org-avatar-icon-collapsed {
  max-height: unset;
  min-height: 66px;
}

.navigation-menu:not(.ant-menu-inline-collapsed),
.subnavigation-menu:not(.ant-menu-inline-collapsed) {
  min-width: $navigationWidth;
}

.subnavigation-menu.ant-menu-inline-collapsed,
.navigation-menu.ant-menu-inline-collapsed {
  min-width: $navigationWidthCollapsed;
}

.navigation-org-name {
  font-size: $small-font;
  color: $pure-white;
}

.navigation-menu > .navigation-divider {
  border-color: rgba(255, 255, 255, 0.1);

  &.navigation-divider-top {
    margin: 0 0 0 0;
  }

  &.navigation-divider-bottom {
    margin: 0 0 0 0;
  }

  &.navigation-divider-hidden {
    display: none;
  }
}

.navigation-menu:not(.ant-menu-inline-collapsed) {
  & > .navigation-divider.navigation-divider-top {
    margin: 0 0 0 0;
  }
}

.navigation-menu.navigation-menu,
.subnavigation-menu.subnavigation-menu {
  > .ant-menu-item {
    &.navigation-toggler,
    &.navigation-org-switcher {
      min-height: 24px;
      max-height: 24px;
      height: 24px;

      .ant-menu-title-content {
        font-size: 12px;
        line-height: 12px;
      }
      .anticon {
        font-size: 12px;
        line-height: 12px;
        margin-top: math.div($margin, 3);
      }
    }
  }
}

.navigation-menu.navigation-menu,
.subnavigation-menu.subnavigation-menu {
  > .ant-menu-item {
    &.navigation-org-switcher {
      min-height: 0;
      max-height: 28px;
      height: 28px;
      margin-top: -16px;

      .ant-menu-title-content {
        font-size: 13px;
        line-height: 13px;
      }
      .anticon {
        font-size: 13px;
        line-height: 13px;
        margin-top: math.div($margin, 4);
      }
    }
  }
}

.navigation-user-menu,
.navigation-help-menu {
  .ant-menu-submenu-arrow {
    &::before,
    &::after {
      display: none;
    }
  }

  .ant-menu-sub.ant-menu-sub {
    background-color: #001529;
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    height: 18px;
    line-height: 18px;
    min-height: 18px;
    padding-left: 22px !important;
    font-size: 12px;
    margin-top: -3px;
    margin-bottom: $margin;

    &.ant-menu-item-selected {
      background-color: #001529;

      .ant-menu-title-content a {
        border-bottom: 2px solid $link-blue;
      }
    }
  }
}

.navigation-menu .navigation-main-menu-item {
  &.ant-menu-submenu,
  &.ant-menu-item:not(.ant-menu-item-selected) {
    &,
    > span > a,
    .anticon,
    .ant-menu-title-content {
      color: rgba(255, 255, 255, 0.8);

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.navigation-menu .help-option-item-expanded.ant-menu-item {
  height: 16px;
  line-height: 16px;
  min-height: 16px;
  padding-left: 22px !important;
  font-size: 12px;

  &.help-option-item-expanded-first {
    margin-top: $margin;
  }

  &.help-option-item-expanded-last {
    margin-bottom: $margin;
  }
}

.navigation-top-org-menu {
  .ant-menu-submenu-arrow {
    &::before,
    &::after {
      display: none;
    }
  }

  .ant-menu-item-icon + span,
  .anticon + span {
    margin-left: $margin-third;
  }

  .ant-menu-sub.ant-menu-sub {
    background-color: #001529;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    height: 24px;
    line-height: 24px;
    min-height: 24px;
    padding-left: 28px !important;
    font-size: 12px;
    margin-top: -15px;
    margin-bottom: $margin-half;
    &.ant-menu-item-selected {
      background-color: #001529;
      color: $pure-white;

      .ant-menu-title-content a {
        border-bottom: 2px solid $link-blue;
      }
    }
  }
}

.navigation-org-name {
  font-size: 12px !important;
  font-weight: 500;
  color: $pure-white;
}
