@import "~antd/dist/antd.css";
@import "~@valencediscovery/kernel.styles/dist/variables.scss";
@import "~@valencediscovery/kernel.styles/dist/breakpoints.scss";

body {
  overflow-y: hidden;
}

.app-header {
  .ant-preview .ant-tooltip-inner {
    color: yellow;
    background-color: green;
  }

  .ant-switch {
    background-color: lightgray;
  }

  .ant-switch-checked {
    background-color: #1890ff;
  }
}

.ant-badge-status-dot {
  width: 8px;
  height: 8px;
}

.ant-layout-header {
  padding-left: $margin;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navigation-toggle {
  margin-right: $margin-half;
  margin-left: $margin-third;

  &,
  &:active,
  &:focus {
    background-color: $navigation-color;
    border-color: transparent;
    color: $pure-white;
  }

  &:hover {
    background-color: $navigation-color;
    border-color: $pure-white;
    color: $pure-white;
  }
}

.header-text {
  color: $pure-white;
}

.app-container {
  display: flex;
  flex-direction: row;
  background-color: white;
}

.main-wrapper:not(.main-wrapper-with-side-nav) {
  padding: 24px 40px;
  max-height: 100vh;
  min-height: 100vh;
  background-color: $pure-white;
  overflow-y: scroll;
  z-index: 1;

  @include medium-device {
    padding: 40px 32px;
  }
}

.main-wrapper-hide {
  background-color: transparent;
  transition: background-color 50ms linear;
  cursor: pointer;
}

.navigation-menu:not(.ant-menu-inline-collapsed) ~ .main-wrapper-hide {
  @include medium-device {
    position: absolute;
    top: 0;
    left: $navigationWidth;
    width: calc(100vw - #{$navigationWidth});
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    z-index: 2;
  }
}

// main wrapper with side nav

.navigation-menu:not(.ant-menu-inline-collapsed) ~ .main-wrapper-with-side-nav {
  max-width: calc(100vw - #{$navigationWidth});
  width: calc(100vw - #{$navigationWidth});
}

.navigation-menu.ant-menu-inline-collapsed ~ .main-wrapper-with-side-nav {
  max-width: calc(100vw - #{$navigationWidthCollapsed});

  @include medium-device {
    max-width: 100vw;
  }
}

.main-wrapper.main-wrapper-with-side-nav {
  z-index: 1;
  flex-wrap: nowrap;

  .main-wrapper-col {
    padding: 40px 24px 0 24px;
  }

  .main-wrapper-side-nav-col,
  .main-wrapper-col {
    max-height: 100vh;
    min-height: 100vh;
  }

  .main-wrapper-side-nav-col {
    border-right: 1px solid #f0f0f0;
    box-shadow: 2px 0 5px #f0f0f0;
  }

  .main-wrapper-col {
    overflow-x: scroll;
  }

  .subnavigation-menu {
    border-right: 0;
  }

  .subnavigation-toggler {
    position: absolute;
    z-index: 1;
    top: 0;
    background-color: transparent;
    right: -27px;

    .ant-btn {
      border-top: none;
      border-left: none;
      background-color: transparent;
    }
  }
}
